<template>
  <div>
    <PreLoader v-if="loading" />
    <OfferDetails v-if="data && !loading" :details="data.acf" />
    <OfferGuardian v-if="data && !loading" :guardian="data.acf.guardian[0]" />
  </div>
</template>

<script>
import OfferGuardian from "../components/OfferGuardian.vue";
import OfferDetails from "../components/OfferDetails.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import PreLoader from "../components/PreLoader.vue";

export default {
  components: {
    PreLoader,
    OfferGuardian,
    OfferDetails,
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    const slug = route.params.slug;

    onMounted(() => {
      fetchData();
    });

    function fetchData() {
      loading.value = true;
      var url = new URL(
        "https://skupnieruchomoscigrupa.pl/api/wp-json/wp/v2/locals"
      );

      const params = {
        _fields: "id,slug,acf",
        slug: slug,
      };

      url.search = new URLSearchParams(params).toString();

      return fetch(url, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((response) => {
          data.value = response[0];
        })
        .catch((err) => {
          error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    return {
      data,
      loading,
      error,
    };
  },
};
</script>
