import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/HomePage.vue";
import Property from "@/pages/PropertyPage.vue";
import SlugPage from "@/pages/SlugPage.vue";
import Contact from "@/pages/ContactPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/nieruchomosc/:slug",
    name: "Property",
    component: Property,
  },
  {
    path: "/:slug",
    name: "SlugPage",
    component: SlugPage,
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;