<template>
  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-end" aria-label="Footer">
        <div v-for="item in navigation.main" :key="item.name" class="px-5 py-2">
          <a
            :href="item.href"
            class="text-base text-gray-500 hover:text-gray-900"
          >
            {{ item.name }}
          </a>
        </div>
      </nav>
      <p class="mt-3 text-right text-base text-gray-400">
        &copy; 2022 Skup Nieruchomości Śląsk
      </p>
    </div>
  </footer>
</template>

<script>
const navigation = {
  main: [
    { name: "Lista nieruchomości", href: "/" },
    { name: "O nas", href: "/o-nas/" },
    { name: "Współpraca", href: "/wspolpraca/" },
    { name: "Kontakt", href: "/kontakt/" },
    { name: "2022", href: "https://m2kupimy.pl" },
  ],
};

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>
