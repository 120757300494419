<template>
  <div>
    <PreLoader v-if="loading" />
    <div
      class="py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden"
      v-if="data && !loading"
    >
      <div class="max-w-max lg:max-w-7xl mx-auto">
        <div class="relative z-10 mb-8 md:mb-2 md:px-6">
          <div class="text-base max-w-prose lg:max-w-none">
            <h2
              class="leading-6 text-indigo-600 font-semibold tracking-wide uppercase"
              v-html="data.acf.additional_header"
            />
            <h1
              class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              v-html="data.title.rendered"
            />
          </div>
        </div>
        <div class="relative">
          <div class="relative md:bg-white md:p-6">
            <div class="lg:grid lg:grid-cols-1 lg:gap-6">
              <div
                class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none"
                v-html="data.content.rendered"
              />
            </div>
            <div
              class="mt-8 inline-flex rounded-md shadow"
              v-if="data.acf.button.show"
            >
              <router-link
                :to="data.acf.button.url"
                class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >{{ data.acf.button.text }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import PreLoader from "../components/PreLoader.vue";

export default {
  components: { PreLoader },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    watch(
      route,
      (to) => {
        fetchData(to.params.slug);
      },
      { flush: "pre", immediate: true, deep: true }
    );

    function fetchData(slug) {
      loading.value = true;
      var url = new URL(
        "https://skupnieruchomoscigrupa.pl/api/wp-json/wp/v2/pages"
      );

      const params = {
        _fields: "id,slug,acf,title,content",
        slug: slug,
      };

      url.search = new URLSearchParams(params).toString();

      return fetch(url, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((response) => {
          data.value = response[0];
        })
        .catch((err) => {
          error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    return {
      data,
      loading,
      error,
    };
  },
};
</script>
