<template>
  <div>
    <PreLoader v-if="loading" />
    <div v-if="data" class="bg-white py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div class="relative max-w-xl mx-auto">
        <div class="text-center">
          <h1
            class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            v-html="data.title.rendered"
          />
          <div class="mt-4 text-lg" v-html="data.content.rendered" />
        </div>
        <div class="mt-12">
          <form
            action="#"
            method="POST"
            :class="{ opacity: loading }"
            class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            @submit.prevent="sendForm"
          >
            <div>
              <label
                for="first-name"
                class="block text-sm font-medium text-gray-700"
                >Imię</label
              >
              <div class="mt-1">
                <input
                  :disabled="loading"
                  v-model="form.firstName"
                  required
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700"
                >Nazwisko</label
              >
              <div class="mt-1">
                <input
                  :disabled="loading"
                  v-model="form.lastName"
                  required
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="email" class="block text-sm font-medium text-gray-700"
                >Email</label
              >
              <div class="mt-1">
                <input
                  :disabled="loading"
                  v-model="form.email"
                  required
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="message"
                class="block text-sm font-medium text-gray-700"
                >Wiadomość</label
              >
              <div class="mt-1">
                <textarea
                  :disabled="loading"
                  v-model="form.message"
                  required
                  id="message"
                  name="message"
                  rows="4"
                  class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <Switch
                    :disabled="loading"
                    v-model="agreed"
                    required
                    :class="[
                      agreed ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    ]"
                  >
                    <span class="sr-only">Zgoda</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        agreed ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]"
                    />
                  </Switch>
                </div>
                <div class="ml-3">
                  <p
                    class="text-base text-gray-500"
                    v-html="data.acf.agreement"
                  />
                </div>
              </div>
            </div>
            <div class="sm:col-span-2">
              <button
                :disabled="loading"
                type="submit"
                class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Wyślij
              </button>
            </div>
            <div v-if="success" class="sm:col-span-2 text-center font-bold">
              Dziękujemy za kontakt, wiadomość została wysłana!
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Switch } from "@headlessui/vue";
import PreLoader from "../components/PreLoader.vue";

export default {
  components: {
    Switch,
    PreLoader,
  },
  setup() {
    const agreed = ref(false);
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const success = ref(false);
    const form = ref({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    });

    onMounted(() => {
      fetchData();
    });

    function fetchData() {
      loading.value = true;
      var url = new URL(
        "https://skupnieruchomoscigrupa.pl/api/wp-json/wp/v2/pages"
      );

      const params = {
        _fields: "id,slug,acf,title,content",
        slug: "skontaktuj-sie-z-nami",
      };

      url.search = new URLSearchParams(params).toString();

      return fetch(url, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((response) => {
          data.value = response[0];
        })
        .catch((err) => {
          error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    return {
      agreed,
      data,
      loading,
      error,
      form,
      success,
    };
  },
  methods: {
    sendForm() {
      var url = new URL(
        "https://skupnieruchomoscigrupa.pl/api/form-carter/index.php"
      );

      const formData = new FormData();
      formData.set(
        "imię i nazwisko",
        this.form.firstName + " " + this.form.lastName
      );
      formData.set("mail", this.form.email);
      formData.set("wiadomość", this.form.message);
      formData.set("zgoda", this.agreed ? "Tak" : "Nie");

      this.loading = true;

      return fetch(url, {
        body: new URLSearchParams(formData),
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            this.error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((response) => {
          if (response.data.message === "OK") {
            this.success = true;
            this.form.firstName = "";
            this.form.lastName = "";
            this.form.email = "";
            this.form.message = "";
            this.agreed = false;
          }
        })
        .catch((err) => {
          this.error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              this.error.value.message = json.message;
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
</style>
