<template>
  <TheHeader />
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="w-full py-6">
      <router-view />
    </div>
  </div>
  <TheFooter />
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>
