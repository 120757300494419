<template>
  <div class="relative bg-white">
    <div class="lg:mx-auto lg:max-w-7xl lg:gap-24 lg:items-start">
      <carousel :items-to-show="1">
        <slide v-for="slide in details.photos" :key="slide.ID">
          <div
            class="w-full aspect-w-16 aspect-h-9 overflow-hidden bg-gray-100"
          >
            <img
              class="w-full h-full object-center object-contain"
              :src="slide.url"
              alt=""
            />
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
      <h1
        class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
      >
        {{ details.city }} {{ details.street }}
      </h1>
      <p class="text-gray-500">
        {{ details.measurment }} m<sup>2</sup> /
        {{ priceFormat(Math.round(details.price / details.measurment)) }}
        zł/m<sup>2</sup> / {{ details.liczba_pokoi }} pok.
      </p>
      <p class="font-medium text-gray-900 text-xl">
        {{ priceFormat(details.price) }}
      </p>

      <div class="relative">
        <div class="mt-10">
          <div
            class="flex flex-col items-start md:flex-row md:items-end justify-between"
          >
            <h2
              class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
            >
              Najważniejsze informacje
            </h2>
            <a
              v-if="details.pdf_attachment"
              target="_blank"
              :href="details.pdf_attachment.url"
              download
              class="inline-flex items-center rounded-md border border-gray-300 bg-indigo-500 px-3 py-2 mt-4 md:mt-0 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
            >
              Pobierz ofertę PDF
            </a>
          </div>

          <dl class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-10">
            <div class="border-t-2 border-gray-100 pt-6">
              <dt class="text-base font-medium text-gray-500">Dostępne od</dt>
              <dd class="text-2xl font-extrabold tracking-tight text-gray-900">
                {{ details.availability }}
              </dd>
            </div>
            <div class="border-t-2 border-gray-100 pt-6">
              <dt class="text-base font-medium text-gray-500">Prowizja</dt>
              <dd class="text-2xl font-extrabold tracking-tight text-gray-900">
                {{ details.commission }}
              </dd>
            </div>
            <div class="border-t-2 border-gray-100 pt-6">
              <dt class="text-base font-medium text-gray-500">Alert</dt>
              <dd class="text-2xl font-extrabold tracking-tight text-gray-900">
                {{ details.alert }}
              </dd>
            </div>
            <div class="border-t-2 border-gray-100 pt-6">
              <dt class="text-base font-medium text-gray-500">
                Możliwość publikacji
              </dt>
              <dd class="text-2xl font-extrabold tracking-tight text-gray-900">
                {{ details.possibilityOfPublication }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="relative">
        <div class="mt-20">
          <h2
            class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
          >
            Detale oferty
          </h2>
          <dl class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-10">
            <div
              v-for="detail in detailsWithKeys"
              :key="detail[0]"
              class="border-t-2 border-gray-100 pt-6"
            >
              <dt class="text-base font-medium text-gray-500">
                {{ keysTranslation[detail[0]] }}
              </dt>
              <dd class="font-extrabold tracking-tight text-gray-900">
                {{ detail[1] }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div class="relative">
        <div class="pt-12 sm:pt-16 lg:pt-20">
          <h2
            class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
          >
            Opis oferty
          </h2>
          <div
            class="mt-6 text-gray-500 space-y-6"
            v-html="details.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

const keysTranslation = {
  price: "Cena",
  commission: "Prowizja",
  rent: "Czynsz",
  liczba_pokoi: "Liczba pokoi",
  building_floors: "Piętra w budynku",
  floor: "piętro",
  type_of_building: "Typ budynku",
  heating: "Ogrzewanie",
  condition: "Stan",
  balcony: "Balkon",
  basement: "Piwnica",
  alert: "Alert",
  measurment: "Metry kwadratowe",
  city: "Miejscowość",
  street: "Ulica",
  availability: "Dostępność",
  ownership: "Rodzaj własności",
  possibility_of_publication: "Możliwość publikacji",
  keys: "Klucze",
  intercom_code: "Kod domofonu",
};

const excludedKeys = [
  "photos",
  "guardian",
  "description",
  "alert",
  "price",
  "commission",
  "measurment",
  "city",
  "street",
  "availability",
  "possibilityOfPublication",
  "pdf_attachment",
];

const objectOrder = {
  type_of_building: null,
  measurment: null,
  liczba_pokoi: null,
  floor: null,
  building_floors: null,
  heating: null,
  condition: null,
  balcony: null,
  basement: null,
  ownership: null,
  rent: null,
  keys: null,
  intercom_code: null,
};

export default {
  props: ["details"],
  setup() {
    return {
      keysTranslation,
      excludedKeys,
      objectOrder,
    };
  },
  computed: {
    detailsWithKeys() {
      const workingObject = { ...this.objectOrder, ...this.details };
      return Object.entries(workingObject).filter(
        (o) => !this.excludedKeys.includes(o[0])
      );
    },
  },
  methods: {
    priceFormat(price) {
      return new Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: "PLN",
      }).format(price);
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
