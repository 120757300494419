<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16">
      <div class="max-w-lg mx-auto md:max-w-none md:gap-8">
        <div>
          <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">Opiekun oferty</h2>
          <div class="mt-3">
            <p class="text-lg text-gray-500">Skontaktuj się z opiekunem oferty, aby uzyskać więcej szczegółów na temat proponowanej nieruchomości.</p>
          </div>
          <div class="mt-9" v-if="data">
            <div class="flex">
              <div class="flex-shrink-0">
                <EmojiHappyIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <h3>{{ data.title.rendered }}</h3>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p><a :href="`tel:${data.acf.phone}`">{{ data.acf.phone }}</a></p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p><a :href="`mailto:${data.acf.email}`">{{ data.acf.email }}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmojiHappyIcon, MailIcon, PhoneIcon } from '@heroicons/vue/outline'
import { ref, onMounted } from "vue"

export default {
  props: ['guardian'],
  components: {
    EmojiHappyIcon,
    MailIcon,
    PhoneIcon,
  },
  setup(props) {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);

    onMounted(() => {
      fetchData();
    });

    function fetchData() {
      loading.value = true;
      var url = new URL(`https://skupnieruchomoscigrupa.pl/api/wp-json/wp/v2/guardians/${props.guardian}`)

      const params = {
        _fields: "id,title,slug,acf",
      }

      url.search = new URLSearchParams(params).toString();

      return fetch(url, {
        method: 'get',
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then(response => {
          data.value = response;
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    return {
      data,
      loading,
      error
    };
  }
}
</script>