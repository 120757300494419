<template>
  <div>
    <div class="mx-auto pb-8 overflow-hidden">
      <div
        class="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8"
        v-if="filteredProperties"
      >
        <div
          v-for="property in filteredProperties"
          :key="property.id"
          class="group text-sm"
        >
          <carousel v-if="property.acf.photos.length > 1" :items-to-show="1">
            <slide v-for="slide in property.acf.photos" :key="slide.ID">
              <div
                class="w-full aspect-w-16 aspect-h-9 overflow-hidden bg-gray-100"
              >
                <img
                  class="w-full h-full object-center object-cover"
                  :src="slide.url"
                  alt=""
                />
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
          <div
            v-else
            class="w-full aspect-w-16 aspect-h-9 overflow-hidden bg-gray-100 mb-5"
          >
            <img
              class="w-full h-full object-center object-cover"
              :src="property.acf.photos[0]?.url"
              alt=""
            />
          </div>
          <router-link :to="`/nieruchomosc/${property.slug}/`">
            <h3 class="font-medium text-gray-900">
              {{ property.acf.city }} {{ property.acf.street }}
            </h3>
            <p class="text-gray-500">
              {{ property.acf.measurment }} m<sup>2</sup> /
              {{
                priceFormat(
                  Math.round(property.acf.price / property.acf.measurment)
                )
              }}
              zł/m<sup>2</sup> / {{ property.acf.liczba_pokoi }} pok.
            </p>
            <p class="font-medium text-gray-900 text-xl">
              {{ priceFormat(property.acf.price) }}
            </p>
            <button
              class="inline-flex items-center rounded-md border border-gray-300 bg-indigo-500 px-3 py-2 mt-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
            >
              Przejdź do obiektu
            </button>
          </router-link>
        </div>
      </div>
      <div
        v-if="filteredProperties && filteredProperties.length === 0"
        class="text-xl text-gray-900 font-extrabold tracking-tight text-center"
      >
        Brak obiektów spełniających kryteria
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  props: ["rooms", "city", "properties"],
  methods: {
    priceFormat(price) {
      return new Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: "PLN",
      }).format(price);
    },
  },
  computed: {
    filteredProperties() {
      if (this.properties) {
        if (this.city && this.rooms) {
          return this.properties.filter(
            (p) => p.acf.city === this.city && p.acf.liczba_pokoi === this.rooms
          );
        } else if (this.city && !this.rooms) {
          return this.properties.filter((p) => p.acf.city === this.city);
        } else if (this.rooms && !this.city) {
          return this.properties.filter(
            (p) => p.acf.liczba_pokoi === this.rooms
          );
        } else {
          return this.properties;
        }
      } else {
        return null;
      }
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style scss>
body {
  --vc-nav-background-color: rgb(75, 85, 99, 0.7);
  --vc-pgn-active-color: rgb(75, 85, 99);
  --vc-pgn-background-color: rgb(135, 133, 139);
  --vc-nav-width: 25px;
}

.carousel__prev {
  left: 25px;
}
.carousel__next {
  right: 25px;
}
</style>
