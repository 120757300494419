<template>
  <Listbox as="div" v-model="localValue" v-bind="$attrs" >
    <ListboxLabel class="block text-sm font-medium text-gray-700">{{ title }}</ListboxLabel>
    <div class="relative mt-1 p-1">
        <ListboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />  
        </ListboxButton>
        <ListboxButton class="w-full flex rounded-md border border-gray-300 align-left bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
            <span class="ml-2">{{localValue}}&nbsp;</span>
        </ListboxButton> 

        <ListboxOptions v-if="values.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-for="(value, index) in values" :key="index" :value="value" as="template" v-slot="{ active, selected }">
            <li :class="['relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                <span :class="['block truncate', selected && 'font-semibold']">
                {{ value }}
                </span>

                <span v-if="selected" :class="['absolute inset-y-0 left-0 flex items-center pl-1.5', active ? 'text-white' : 'text-indigo-600']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
            </li>
            </ListboxOption>
        </ListboxOptions>
    </div>
  </Listbox>
</template>

<script>
import { computed, ref } from "vue";
import {  SelectorIcon, CheckIcon } from '@heroicons/vue/solid'
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";

export default {
  props: ["title", "values", "modelValue"],
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon
  },
  setup(props, context) {
    const query = ref("");
    const localValue = computed({
      get: () => props.modelValue,
      set: (value) => context.emit("update:modelValue", value),
    });

    return {
      query,
      localValue,
    };
  },
  emits: ["update:modelValue"],
};
</script>
