<template>
  <header class="bg-gray-600">
    <nav class="px-4 mx-auto max-w-7xl sm:px-6 xl:px-8" aria-label="Top">
      <div
        class="flex flex-wrap items-center justify-between w-full py-6 border-b border-gray-500 xl:border-none"
      >
        <div class="flex items-center text-white">
          <router-link to="/" class="flex items-center mb-3 font-bold md:mb-0">
            <img src="/logo.png" class="object-contain w-12 h-auto" />
          </router-link>
          <div class="hidden ml-10 space-x-8 xl:block">
            <router-link
              v-for="link in navigation"
              :key="link.name"
              :to="link.href"
              class="text-base font-medium hover:text-indigo-50"
            >
              {{ link.name }}
            </router-link>
          </div>
        </div>
        <div class="ml-10 space-x-4 text-white xl:text-lg">
          PORTAL TYLKO DLA AGENTÓW I SOURCERÓW
        </div>
      </div>
      <div class="flex flex-wrap justify-center py-4 space-x-6 xl:hidden">
        <router-link
          v-for="link in navigation"
          :key="link.name"
          :to="link.href"
          class="text-base font-medium text-white hover:text-indigo-50"
        >
          {{ link.name }}
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
const navigation = [
  { name: "O nas", href: "/o-nas/" },
  { name: "Współpraca", href: "/wspolpraca/" },
  { name: "Kontakt", href: "/kontakt/" },
];

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>
