<template>
  <h1 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
    Znajdź nieruchomość
  </h1>
  <p class="mt-2 text-sm text-gray-700">
    Poniżej lista wszystkich dostępnych nieruchomości
  </p>
  <div class="mx-auto py-8 overflow-hidden">
    <div class="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-4 lg:gap-x-8">
      <div class="group text-sm z-10 relative col-span-1" v-if="properties">
        <ListBox title="miasto" :values="uniqueCities" v-model="city" />
        <ListBox
          title="liczba pokoi"
          :values="uniqueRoomsCount"
          class="pt-2"
          v-model="rooms"
        />
        <button
          class="inline-flex items-center rounded-md border border-gray-300 bg-indigo-500 px-3 py-2 mt-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
        >
          Szukaj
        </button>
        <button
          v-if="city || rooms"
          class="inline-flex items-center rounded-md border border-gray-300 bg-gray-400 px-3 py-2 mt-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30 ml-2"
          @click="clearFilters"
        >
          wyczyść filtrację
        </button>
      </div>
      <DummyFilters v-if="loading" />
      <div class="col-span-3">
        <PropertiesGrid
          v-if="!loading"
          :properties="properties"
          :rooms="rooms"
          :city="city"
        />
        <DummyOffert v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import PropertiesGrid from "../components/PropertiesGrid.vue";
import ListBox from "../components/ListBox.vue";
import { ref, onMounted } from "vue";

export default {
  components: {
    PropertiesGrid,
    ListBox,
  },
  setup() {
    const properties = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const rooms = ref("");
    const city = ref("");

    onMounted(() => {
      fetchData();
    });

    function fetchData() {
      loading.value = true;

      var url = new URL(
        "https://skupnieruchomoscigrupa.pl/api/wp-json/wp/v2/locals"
      );

      const params = {
        per_page: 300,
        _fields: "id,slug,acf",
      };

      url.search = new URLSearchParams(params).toString();

      return fetch(url, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then((response) => {
          properties.value = response;
        })
        .catch((err) => {
          error.value = err;
          if (err.json) {
            return err.json.then((json) => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    return {
      properties,
      loading,
      error,
      rooms,
      city,
      help: true,
    };
  },
  methods: {
    clearFilters() {
      this.rooms = "";
      this.city = "";
    },
  },
  computed: {
    uniqueCities() {
      return [
        ...new Set(
          this.properties.reduce((a, c) => [...a, c.acf.city], []).sort()
        ),
      ];
    },
    uniqueRoomsCount() {
      return [
        ...new Set(
          this.properties
            .reduce((a, c) => [...a, c.acf.liczba_pokoi], [])
            .sort()
        ),
      ];
    },
  },
};
</script>
